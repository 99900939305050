<script>


import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import { getCashApi } from '@/api/cash'


// import flatPickr from "vue-flatpickr-component";
// import "flatpickr/dist/flatpickr.css";
import Choices from "choices.js";


import PrintChequeDialog from './components/print_cheque_dialog.vue'

export default {
  page: {
    title: "Trust Account Mgt",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      list: [],
      title: "Trust Account Mgt",
      items: [
        {
          text: "Account Payable",
          href: "/",
        },
        {
          text: "Trust Account Mgt",
          href: "/",
          active: true
        },
      ],
      totalRows: 0,
      currentPage: 1,
      perPage: 100,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "value",
      sortDesc: false,
      fields: [
        {
          key: "G",
          label: '',
          sortable: true,
        },


        {
          key: "Payee",
          sortable: true,
        },

        {
          key: "PaymentDate",
          sortable: true,
        },
        {
          key: "Amount",
          sortable: false,
        },

        {
          key: "Reference",
          sortable: true,
        },


        {
          key: "Status",
          sortable: false,
        },
      ],
      rangeOptions: {
        mode: "range",
      },


      pay_type: '',
      query_type: '',
      query_agent_id: '',
      query_deal_id: '',
      queryRange: '',
      query_status: 'PENDING',
      selected_transactions: [],
      expense: {}
    };
  },
  components: {
    Layout,
    PageHeader,
    //flatPickr,
    PrintChequeDialog
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.totalRows
    },

    cheque_btn_disabled_flag() {
      if (this.selected_transactions.length <= 0) {
        return true
      }

      let first_payee = this.selected_transactions[0].payee
      let payee_list = this.selected_transactions.filter(t => t.payee == first_payee)
      return this.selected_transactions.length != payee_list.length
    },

    eft_btn_disabled_flag() {
      if (this.selected_transactions.length <= 0) {
        return true
      } else {
        return false
      }
    }
  },

  created() {
    this.queryList()
  },
  mounted() {

    new Choices('#status_choice', {
    });

  },
  methods: {

    queryList() {

      let data = {
        page: {
          page_no: this.currentPage,
          page_size: this.perPage
        },
        query_type: 'DEAL',
        pay_type: this.pay_type,
      }

      getCashApi().ap_list(data).then((res) => {
        this.list = []
        res.data.map((o) => {
          this.list.push(o)
          this.totalRows = res.page.total;

        })

      })
    },

    pageChange(p) {
      this.currentPage = p
      this.queryList()
    },

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },



    onSearch(item) {
      this.currentPage = 1;
      if (item.agent) {
        this.query_agent_id = item.agent.id
      } else {
        this.query_agent_id = ''
      }

      if (item.deal) {
        this.query_deal_id = item.deal.id
      } else {
        this.query_deal_id = ''
      }

      this.queryList()
    },

    expense_save(expense) {
      console.log(expense)
    },


    onSelectedTransaction(evt, t) {
      if (evt.target.checked == true) {
        let d = this.selected_transactions.find((e) => e.id == t.id)
        if (!d) {
          this.selected_transactions.push(t)
        }
      } else {
        this.selected_transactions = this.selected_transactions.filter((e) => e.id != t.id)
      }
    },

    onEftSetup() {
      this.$bvModal.hide('modal-eft-payment-dialog')
    }
  },

};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-modal centerd id="modal-cheque-print-dialog" title="Cheque Print" size="lg" hide-footer>
      <PrintChequeDialog :transactions="selected_transactions" :bank_account="'Trust account'"
        @cancel="$bvModal.hide('modal-cheque-print-dialog')" @confirm="onEftSetup()" />
    </b-modal>


    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <div class="row">



              <div class="mb-2 col-md-3 ">
                <select name="choices-multiple-remove-button" id="status_choice" v-model="query_status"
                  @change="queryList()">

                  <option value="PROGRESS">Progress</option>
                  <option value="COMPLETED">Completed</option>
                  <option value="CANCELLED">Cancelled</option>
                  <option value="PENDING" selected>Pending</option>
                </select>

              </div>

              <div class="col-md-9 d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2">

                <b-button variant="info" :disabled="cheque_btn_disabled_flag"
                  v-b-modal.modal-cheque-print-dialog>Cheque</b-button>
              </div>
              <!-- end col -->


            </div>
             <!-- end row -->


          </div>
          <div class="card-body">



            <div class="table-responsive">
              <div class="table align-middle table-nowrap">
                <b-table :items="list" :fields="fields" responsive="sm" :per-page="perPage" :sort-by.sync="sortBy"
                striped hover 
                  :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered"
                  class="table-check">

                  <template #cell(G)="data">
                    <input type="checkbox" @change="onSelectedTransaction($event, data.item)" />

                  </template>
                  <template #cell(ID)="row">
                    <feather :type="row.detailsShowing ? 'chevron-down' : 'chevron-right'" @click="row.toggleDetails">
                    </feather>
                  </template>

                  <template #cell(Payee)="data">
                    {{ data.item.payee }}
                  </template>

                  <template #cell(Amount)="data">
                    ${{ data.item.amount.toLocaleString() }}
                  </template>

                  <template #cell(PaymentDate)="data">
                    {{ data.item.due_date }}
                  </template>

                  <template #cell(Reference)="data">
                    <span v-if="data.item.cash_record.ref_type == 'DEAL' || data.item.cash_record.ref_type == 'FEE'">
                      <router-link
                        :to="{ name: 'deal-overview', query: { deal_id: `${data.item.cash_record.deal.order_deal_id}` } }"
                        class="text-body fw-medium">{{ data.item.cash_record.deal.order_deal_id }}</router-link>
                    </span>
                    <span v-else>
                      {{ data.item.ref_id }}
                    </span>

                  </template>


                  <template #cell(Status)="data">
                    <b-badge pill class="badge-soft-primary me-1 font-size-12" v-if="data.item.status =='PENDING'">
                      {{ 'Pending' }}
                    </b-badge>
                    <b-badge pill class="badge-soft-dark me-1 font-size-12" v-else-if="data.item.status =='COMPLETED'">
                      {{ 'Completed' }}
                    </b-badge>
                    <b-badge pill class="badge-soft-success me-1 font-size-12" v-else-if="data.item.status =='PROGRESS'">
                      {{ 'Progress' }}
                    </b-badge>
                    <b-badge pill class="badge-soft-warning me-1 font-size-12" v-else-if="data.item.status =='CANCELLED'">
                      {{ 'Cancelled' }}
                    </b-badge>

                  </template>
                  <template #cell(Remark)="data">
                    {{ data.item.remarks }}
                  </template>

                  <template v-slot:row-details="row">
                    <div class="table align-middle table-nowrap" style="margin-left:30px;">
                      <b-table :items="row.item.details" :fields="[{ key: 'Type' }, { key: 'SubType' }, { key: 'Amount' }]"
                        responsive="sm" :per-page="row.item.details.length" class="table-check col-md-10">
                        <template #cell(Type)="data">
                          {{ data.item.detail_category }}
                        </template>
                        <template #cell(SubType)="data">
                          {{ data.item.detail_type }}
                        </template>
                        <template #cell(Amount)="data">
                          ${{ data.item.detail_amount.toLocaleString() }}
                        </template>


                      </b-table>
                    </div>
                  </template>

                </b-table>
              </div>
            </div>
            <!-- end table responsive -->

            <div class="row g-0 text-center text-sm-start">
              <!-- end col -->
              <div class="col-sm-12">
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-end">
                      <!-- pagination -->
                      <b-pagination class="pagination-rounded" v-model="currentPage" :total-rows="totalRows"
                        :per-page="perPage" @change="pageChange"></b-pagination>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
